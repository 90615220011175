body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./background.png");
  background-size: 120px;
  overflow: hidden;
}

@media (orientation: portrait) {
  .container {
    background-size: 80px;
    align-items: self-start;
  }
}

body {

  background-color: #efd9a6;
  position: relative;
  overflow: hidden;
}

.playground {
  position: absolute;
}

.shot {
  width: 100px;
  height: 100px;
  background: white;
  position: fixed;
  left: calc(50% - 50px);
  border-radius: 50%;
  bottom: 30px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: #4d4d4d;
}

.download {
  height: 30px;
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: #4d4d4d;
  background: white;
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 10px;
}

.countdown-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 6;
}

.countdown-value {
  font-size: 150px;
  position: absolute;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

.flash-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  opacity: 0;
  display: none;

}

@keyframes flashin {
  0% {
    opacity: 0;
    display: block;
  }

  50% {
    opacity: 1;
    display: block;

  }

  99% {
    opacity: 0;
    display: block;

  }

  100% {
    display: none;
  }
}

.flash {
  animation-duration: .4s;
  animation-name: flashin;
  animation-timing-function: ease-in-out;
  display: block;
  z-index: 9;
}

.actions {
  z-index: 8;
}

.permission-container {
  height: 200px;
  width: 270px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  position: fixed;
  flex-direction: column;
  background: white;
}

.permission-button {
  padding: 10px;
  margin-top: 20px;
  background: #ececec;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.permission-backdrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}


.cover-container {
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0;
}

.user-camera {
  position: absolute;
  width: 32vw;
  left: 32%;
  top: 13%;
}

@media (orientation: portrait) {
  .user-camera {
    width: 48vw;
    left: 20%;
    top: 31%;
  }
}

.compose {
  position: relative;
}

.user-snapshot {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 7;

}